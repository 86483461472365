var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('b-row',[_c('div',{staticClass:"col-lg-2 col-md-2 col-sm-12"},[_c('b-form-group',{attrs:{"label":"نمایش نوع دام ها"},scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('b-form-checkbox-group',{attrs:{"options":_vm.options,"aria-describedby":ariaDescribedby,"switches":"","stacked":""},on:{"change":_vm.Cowprovider},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]}}])}),_c('b-button',{attrs:{"block":"","variant":"primary"},on:{"click":function($event){return _vm.saveLogs()}}},[_vm._v("خروجی اکسل")])],1),_c('div',{staticClass:"col-lg-10 col-md-10 col-sm-12"},[_c('MyDataset',{attrs:{"ds-data":_vm.AllCowData,"ds-filter-fields":{
          cow_type: _vm.onlineFilter,
          cow_num: _vm.startsWithFilter,
        },"ds-sortby":_vm.sortBy,"ds-search-in":['cow_num', 'position', 'cow_type', 'day', 'month'],"ds-search-as":{ cow_num: _vm.searchAsEuroDate }},scopedSlots:_vm._u([{key:"default",fn:function({ ds }){return [_c('div',{staticClass:"row mb-2",attrs:{"data-page-count":ds.dsPagecount}},[_c('div',{staticClass:"col-md-4 mb-2 mb-md-0"},[_c('dataset-show',{attrs:{"ds-show-entries":25,"ds-show-entries-lovs":_vm.ListOfShowData}})],1),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"btn-group mr-1 mb-2"},[_c('b-dropdown',{attrs:{"variant":"primary"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" ترتیب "),_c('i',{staticClass:"mdi mdi-chevron-down"})]},proxy:true}],null,true)},_vm._l((_vm.cols),function(th,index){return _c('b-dropdown-item',{key:th.field,class:['sort', th.sort],on:{"click":function($event){return _vm.clickSort($event, index)}}},[_vm._v(" "+_vm._s(th.name)+" "+_vm._s(th.sort == "asc" ? "⬇️" : "⬆️")+" ")])}),1)],1)]),_c('div',{staticClass:"col-md-4"},[_c('dataset-search',{attrs:{"ds-search-placeholder":"جستجو ..."}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('dataset-item',{staticClass:"form-row mb-3",staticStyle:{"overflow-y":"auto","max-height":"450px"},scopedSlots:_vm._u([{key:"default",fn:function({ row, rowIndex }){return [_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"card mb-2"},[_c('div',{staticClass:"card-body pt-3 pb-2 px-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-7"},[_c('h5',{staticClass:"card-title text-truncate mb-2",attrs:{"title":`Index: ${rowIndex}`}},[_c('span',{class:[
                                'font-16',
                                'text-' + _vm.statusClass[row.cow_type],
                              ]},[_vm._v("⬤")]),_vm._v(" "+_vm._s(row.cow_num)+" ")]),_c('p',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-truncate mb-0",attrs:{"role":"button","data-toggle":"tooltip","title":row.DIM.month + ' ماه '}},[_vm._v(" DIM - "+_vm._s(row.DIM.day)+" ")]),_c('p',{staticClass:"text-truncate mb-0"},[_vm._v(" آخرین تلقیح - "+_vm._s(row.inoculateddays)+" روز قبل ")]),_c('p',{staticClass:"text-truncate mb-0"},[_vm._v(" نوع دام - "+_vm._s(row.cow_type)+" ")]),_c('p',{staticClass:"text-truncate mb-0"},[_vm._v(" جایگاه - "+_vm._s(row.position)+" ")]),_c('p',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-truncate mb-0",attrs:{"role":"button","data-toggle":"tooltip","title":row.day + ' روز '}},[_vm._v(" سن (ماه) - "+_vm._s(row.month)+" ")]),_c('p',{staticClass:"text-truncate text-danger mb-0",attrs:{"role":"button"},on:{"click":function($event){return _vm.ShowMore(row.id)}}},[_vm._v(" بیشتر ")])]),_c('div',{staticClass:"col-5"},[_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"to":'cattel/show/' + row.id}},[_c('img',{staticClass:"rounded",attrs:{"src":_vm.SiteApi +
                                '/upload/img/cow-img-' +
                                _vm.statusClass[row.cow_type] +
                                '.png',"alt":"","height":"90"}})])],1)])])])])]}},{key:"noDataFound",fn:function(){return [_c('div',{ref:"tonotshow",staticClass:"col-md-12 pt-2 d-none",attrs:{"id":"tonotshow"}},[_c('div',{staticClass:"text-center text-success my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v(_vm._s(_vm.text_load))])],1)])]},proxy:true}],null,true)})],1)]),_c('div',{staticClass:"d-flex flex-md-row flex-column justify-content-between align-items-center"},[_c('dataset-info',{staticClass:"mb-2 mb-md-0"}),_c('dataset-pager')],1)]}}])})],1)]),_c('div',{staticClass:"col-sm-6 col-md-4 col-xl-3"},[_c('div',{staticClass:"my-4 text-center"},[_c('b-modal',{attrs:{"scrollable":"","hide-footer":"","title":_vm.Cowtitle,"title-class":"font-18"},model:{value:(_vm.modal_scrollable),callback:function ($$v) {_vm.modal_scrollable=$$v},expression:"modal_scrollable"}},[_c('b-list-group',_vm._l((_vm.CowData),function(item,index){return _c('b-list-group-item',{key:index,staticClass:"flex-column align-items-start"},[_c('div',{staticClass:"d-flex w-100 justify-content-between"},[_c('h6',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.Arraytotext[index]))]),(index == 'DIM')?_c('h6',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"role":"button","data-toggle":"tooltip","title":item.month + ' ماه '}},[_vm._v(" "+_vm._s(item.day)+" روز ")]):_c('h6',[_vm._v(_vm._s(item))])])])}),1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }