<script>
import Layout from "../../layouts/main";
import { dashboardService } from "../../../helpers/dash.service";

import {
  Dataset,
  DatasetItem,
  DatasetInfo,
  DatasetPager,
  DatasetSearch,
  DatasetShow,
} from "vue-dataset";
import { filterList, isoDateToEuroDate, searchAsEuroDate } from "../utilities";

const MyDataset = {
  extends: Dataset,
  name: "MyDataset",
  data() {
    return {
      datasetI18n: {
        show: "نمایش",
        entries: " دام ها",
        previous: "قبلی",
        next: "بعدی",
        showing: "نمایش",
        showingTo: "از",
        showingOf: "کل",
        showingEntries: "دام ها",
      },
    };
  },
};

export default {
  extends: Dataset,
  components: {
    Layout,
    MyDataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    DatasetSearch,
    DatasetShow,
  },
  data: function () {
    return {
      SiteApi: process.env.VUE_APP_BACKEND_URL,
      selected: [],
      options: [
        { text: "تلقیح نشده ها", value: "uninoculated" },
        { text: "نزدیک زایش", value: "nearbirth" },
        { text: "تازه زا", value: "freshman" },
        { text: "چک آبستنی", value: "pregnancycheck" },
      ],
      cols: [
        {
          name: "DIM",
          field: "DIMday",
          sort: "",
        },
        {
          name: "آخرین تلقیح",
          field: "inoculateddays",
          sort: "",
        },
      ],
      modal_scrollable: false,
      startsWith: "",
      onlineFilter: "",
      statusClass: {
        شیری: "success",
        خشک: "danger",
        "تلیسه آبستن": "info",
        "تلیسه غیرآبستن": "warning",
        "گوساله زیر یکسال": "secondary",
      },
      Arraytotext: {
        id: "شناسه",
        cow_num: "شماره دام",
        cow_type: "نوع دام",
        birthtime: "تاریخ تولد",
        serial_num: "شماره سریال",
        registr_num: "شماره ثبت",
        births_num: "شیرواری",
        last_birthtime: "تاریخ آخرین زایش",
        last_inoculatedtime: "تاریخ آخرین تلقیح",
        last_drytime: "تاریخ آخرین خشکی",
        pregnancy_status: "وضعیت بارداری",
        position: "جایگاه",
        set_time: "تاریخ ثبت",
        description: "توضیحات",
        cow_id: "شناسه دام",
        inoculate_time: "تاریخ تلقیح",
        inoculate_round: "نوبت تلقیح",
        breastfeeding: "شیرواری زایش",
        visittime: "تاریخ ویزیت",
        visitresult: "نتیجه ویزیت",
        visitreason: "علت ویزیت",
        drytime: "تاریخ خشکی",
        scoretime: "تاریخ ثبت",
        inoculateddays: "روز های گذشته از تلقیح",
        movement_num: "نمره حرکتی",
        weight: "وزن",
        recorddate: "تاریخ ثبت",
        bcs: "نمره بدنی",
        entrydate: "تاریخ ورود",
        departuredate: "تاریخ خروج",
        reasonentry: "علت ورود",
        month: "سن (ماه)",
        day: "سن (روز)",
        DIM: "DIM",
      },
      Cowtitle: "",
      AllCowData: [],
      last_id: 0,
      text_load: "در حال دریافت اطلاعات",
      CowData: {},
      ListOfShowData: [
        { value: 25, text: 25 },
        { value: 50, text: 50 },
        { value: 100, text: 100 },
        { value: 250, text: 250 },
        { value: 500, text: 500 },
        { value: 1000, text: 1000 },
      ],
    };
  },
  computed: {
    sortBy() {
      return this.cols.reduce((acc, o) => {
        if (o.sort) {
          o.sort === "asc" ? acc.push(o.field) : acc.push("-" + o.field);
        }
        return acc;
      }, []);
    },
  },
  methods: {
    filterList,
    isoDateToEuroDate,
    searchAsEuroDate,
    startsWithFilter(value) {
      return value.toLowerCase().startsWith(this.startsWith.toLowerCase());
    },
    clickSort(event, i) {
      let toset;
      const sortEl = this.cols[i];
      if (!event.shiftKey) {
        this.cols.forEach((o) => {
          if (o.field !== sortEl.field) {
            o.sort = "";
          }
        });
      }
      if (!sortEl.sort) {
        toset = "asc";
      }
      if (sortEl.sort === "desc") {
        toset = event.shiftKey ? "" : "asc";
      }
      if (sortEl.sort === "asc") {
        toset = "desc";
      }
      sortEl.sort = toset;
    },
    Cowprovider() {
      let showData = this.selected;
      if (showData.length) {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "flex";
        let ListOfCow = showData.join(",");
        dashboardService.Warning(ListOfCow).then((data) => {
          this.AllCowData = [];
          for (let index = 0; index < showData.length; index++) {
            switch (showData[index]) {
              case "uninoculated":
                data.message.uninoculated.forEach((element) => {
                  this.AllCowData.push(element);
                });
                break;
              case "nearbirth":
                data.message.nearbirth.forEach((element) => {
                  this.AllCowData.push(element);
                });
                break;
              case "freshman":
                data.message.freshman.forEach((element) => {
                  this.AllCowData.push(element);
                });
                break;
              case "pregnancycheck":
                data.message.pregnancycheck.forEach((element) => {
                  this.AllCowData.push(element);
                });
                break;
            }
          }
          if (data.success) {
            document.getElementById("preloader").style.display = "none";
          }
          const seen = new Set();
          const uniqueChars = this.AllCowData.filter((item) => {
            const duplicate = seen.has(item.id);
            seen.add(item.id);
            return !duplicate;
          });
          this.AllCowData = uniqueChars;
          this.text_load = "یافت نشد";
        });
        if (this.$refs.tonotshow != undefined) {
          this.$refs.tonotshow.classList.remove("d-none");
        }
      } else {
        this.AllCowData = [];
        if (this.$refs.tonotshow != undefined) {
          this.$refs.tonotshow.classList.add("d-none");
        }
      }
    },
    arrayToCsv(data) {
      const array = [Object.keys(data[0])].concat(data);

      return array
        .map((it) => {
          return Object.values(it).toString();
        })
        .join("\n");
    },
    downloadBlob(content, filename, contentType) {
      var blob = new Blob([content], { type: contentType });
      var url = URL.createObjectURL(blob);
      var pom = document.createElement("a");
      pom.href = url;
      pom.setAttribute("download", filename);
      pom.click();
    },
    saveLogs() {
      var nowCows = this.AllCowData.map(function (item) {
        delete item.DIM;
        item.description = item.description.replaceAll(/\s/g, "");
        return item;
      });
      const myLogs = this.arrayToCsv(nowCows);
      const d = new Date();
      this.downloadBlob(
        myLogs,
        "./" + d.getTime() + ".csv",
        "text/csv;charset=utf-8;"
      );
    },
    ShowMore(cow_id) {
      this.AllCowData.filter((data) => {
        if (data.id == cow_id) {
          delete data.DIMday;
          delete data.DIMmonth;
          this.CowData = data;
          this.Cowtitle = data.id;
          this.modal_scrollable = true;
        }
      });
    },
  },
};
</script>
<template>
  <Layout>
    <b-row>
      <div class="col-lg-2 col-md-2 col-sm-12">
        <b-form-group label="نمایش نوع دام ها" v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            v-model="selected"
            :options="options"
            :aria-describedby="ariaDescribedby"
            switches
            stacked
            @change="Cowprovider"
          ></b-form-checkbox-group>
        </b-form-group>
        <b-button @click="saveLogs()" block variant="primary"
          >خروجی اکسل</b-button
        >
      </div>
      <div class="col-lg-10 col-md-10 col-sm-12">
        <MyDataset
          v-slot="{ ds }"
          :ds-data="AllCowData"
          :ds-filter-fields="{
            cow_type: onlineFilter,
            cow_num: startsWithFilter,
          }"
          :ds-sortby="sortBy"
          :ds-search-in="['cow_num', 'position', 'cow_type', 'day', 'month']"
          :ds-search-as="{ cow_num: searchAsEuroDate }"
        >
          <div class="row mb-2" :data-page-count="ds.dsPagecount">
            <div class="col-md-4 mb-2 mb-md-0">
              <dataset-show
                :ds-show-entries="25"
                :ds-show-entries-lovs="ListOfShowData"
              />
            </div>
            <div class="col-md-4">
              <div class="btn-group mr-1 mb-2">
                <b-dropdown variant="primary">
                  <template v-slot:button-content>
                    ترتیب
                    <i class="mdi mdi-chevron-down"></i>
                  </template>
                  <b-dropdown-item
                    v-for="(th, index) in cols"
                    :key="th.field"
                    :class="['sort', th.sort]"
                    @click="clickSort($event, index)"
                  >
                    {{ th.name }}
                    {{ th.sort == "asc" ? "⬇️" : "⬆️" }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="col-md-4">
              <dataset-search ds-search-placeholder="جستجو ..." />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <dataset-item
                class="form-row mb-3"
                style="overflow-y: auto; max-height: 450px"
              >
                <template #default="{ row, rowIndex }">
                  <div class="col-md-4">
                    <div class="card mb-2">
                      <div class="card-body pt-3 pb-2 px-3">
                        <div class="row">
                          <div class="col-7">
                            <h5
                              class="card-title text-truncate mb-2"
                              :title="`Index: ${rowIndex}`"
                            >
                              <span
                                :class="[
                                  'font-16',
                                  'text-' + statusClass[row.cow_type],
                                ]"
                                >⬤</span
                              >
                              {{ row.cow_num }}
                            </h5>
                            <p
                              role="button"
                              class="text-truncate mb-0"
                              v-b-tooltip.hover
                              data-toggle="tooltip"
                              :title="row.DIM.month + ' ماه '"
                            >
                              DIM - {{ row.DIM.day }}
                            </p>
                            <p class="text-truncate mb-0">
                              آخرین تلقیح - {{ row.inoculateddays }} روز قبل
                            </p>
                            <p class="text-truncate mb-0">
                              نوع دام - {{ row.cow_type }}
                            </p>
                            <p class="text-truncate mb-0">
                              جایگاه - {{ row.position }}
                            </p>
                            <p
                              role="button"
                              class="text-truncate mb-0"
                              v-b-tooltip.hover
                              data-toggle="tooltip"
                              :title="row.day + ' روز '"
                            >
                              سن (ماه) - {{ row.month }}
                            </p>
                            <p
                              role="button"
                              class="text-truncate text-danger mb-0"
                              @click="ShowMore(row.id)"
                            >
                              بیشتر
                            </p>
                          </div>
                          <div class="col-5">
                            <router-link
                              :to="'cattel/show/' + row.id"
                              class="side-nav-link-ref"
                            >
                              <img
                                class="rounded"
                                :src="
                                  SiteApi +
                                  '/upload/img/cow-img-' +
                                  statusClass[row.cow_type] +
                                  '.png'
                                "
                                alt
                                height="90"
                              />
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template #noDataFound>
                  <div
                    id="tonotshow"
                    ref="tonotshow"
                    class="col-md-12 pt-2 d-none"
                  >
                    <div class="text-center text-success my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ text_load }}</strong>
                    </div>
                  </div>
                </template>
              </dataset-item>
            </div>
          </div>
          <div
            class="d-flex flex-md-row flex-column justify-content-between align-items-center"
          >
            <dataset-info class="mb-2 mb-md-0" />
            <dataset-pager />
          </div>
        </MyDataset>
      </div>
    </b-row>
    <div class="col-sm-6 col-md-4 col-xl-3">
      <div class="my-4 text-center">
        <b-modal
          v-model="modal_scrollable"
          scrollable
          hide-footer
          :title="Cowtitle"
          title-class="font-18"
        >
          <b-list-group>
            <b-list-group-item
              v-for="(item, index) in CowData"
              :key="index"
              class="flex-column align-items-start"
            >
              <div class="d-flex w-100 justify-content-between">
                <h6 class="mb-1">{{ Arraytotext[index] }}</h6>
                <h6
                  v-if="index == 'DIM'"
                  role="button"
                  v-b-tooltip.hover
                  data-toggle="tooltip"
                  :title="item.month + ' ماه '"
                >
                  {{ item.day }} روز
                </h6>
                <h6 v-else>{{ item }}</h6>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-modal>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
<style scoped>
span.badge {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 10;
  width: 25px;
  height: 15px;
}
</style>